import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  success: boolean = false;
  error: boolean = false;
  formResetPassword: any;

  constructor(
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.initializeForm();
  }

  send() {
    const url_string = window.location.href
    const urlObj = new URL(url_string);
    let token = urlObj.searchParams.get('token')
    let isDev = urlObj.searchParams.get('isDev')
    this.httpClient.post(environment.api+'/api/auth/reset-password', {
      token: token,
      password: this.formResetPassword.value.password,
      password_confirmation: this.formResetPassword.value.password_confirmation,
    }).subscribe(res => {
      this.success = true;
      this.error = false;
    }, err => {
      this.error = true;
      this.success = false;
    })
  }

  initializeForm() {
    this.formResetPassword = this.formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.pattern(/^(?=.{5,})(?=.*[A-Z]).*$/)
      ]],
      password_confirmation: ['', Validators.required],
    }, {
      validators: [this.passwordsMatchValidator]
    })
  }

  private passwordsMatchValidator = (form: FormGroup) => {
    if (form.get('password')?.value && form.get('password_confirmation')?.value) {
      return form.get('password')?.value === form.get('password_confirmation')?.value ? null : { mismatch: true };
    }
    return null;
  }
}
