<div class="container">
  <div class="empty">
      <img src="assets/images/logo.png" alt="">
        <p>Définissez un nouveau mot-de-passe pour accéder à votre compte.</p>
    </div>
  <form class="form" [formGroup]="formResetPassword" novalidate>
      <mat-card *ngIf="success" class="alert success-alert">Votre mot de passe a été bien été modifié.</mat-card>
      <mat-card *ngIf="error" class="alert error-alert">Le lien est invalide ou a expiré.</mat-card>
      
      <div class="info">
          <mat-icon matTooltipPosition="left" matTooltip="5 caractères minimum, 1 majuscule minimum" aria-label="display tooltip" aria-hidden="false" aria-label="help icon">help</mat-icon>
      </div>
      
      <mat-form-field appearance="fill" [class.invalid]="formResetPassword.get('password').touched && formResetPassword.get('password').invalid" [class.valid]="formResetPassword.get('password').touched && formResetPassword.get('password').valid">
          <mat-label>Nouveau mot de passe </mat-label>
          <input matInput type="password" placeholder="Nouveau mot de passe" formControlName="password">
      </mat-form-field>
      <mat-form-field appearance="fill" [class.invalid]="formResetPassword.get('password_confirmation').touched && formResetPassword.get('password_confirmation').invalid || formResetPassword.errors?.mismatch" [class.valid]="formResetPassword.get('password_confirmation').touched && formResetPassword.get('password_confirmation').valid">
          <mat-label>Confirmation du mot de passe</mat-label>
          <input matInput type="password" placeholder="Confirmation du mot de passe" formControlName="password_confirmation">
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="send()" [disabled]="formResetPassword.invalid">Changer mon mot de passe</button>
  </form>
  <div class="empty"></div>
</div>